import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from '@nuvocargo/nuvo-styleguide';
import styled, { css } from 'styled-components';

export default function WithDataMigrationMessage(props) {
  const { children, fab = false, margin, padding } = props;

  return (
    <DataMigrationMessageWrapper fab={fab} margin={margin} padding={padding}>
      <TextWrapper>
        <Trans i18nKey="data-migration-message">
          <p>
            To improve your browsing experience, some historical information has
            been archived.
          </p>
          <p>
            Please reach out to your Customer Success Representative or email{' '}
            <Link href="mailto:support@nuvocargo.com">
              support@nuvocargo.com
            </Link>{' '}
            to retrieve any information not shown.
          </p>
        </Trans>
      </TextWrapper>

      <ChildrenWrapper>{children}</ChildrenWrapper>
    </DataMigrationMessageWrapper>
  );
}

const DataMigrationMessageWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: ${props => props.margin ?? '0'};
  padding: ${props => props.padding ?? '0'};

  ${({ fab }) =>
    fab &&
    css`
      /* FAB is displayed, avoid overlapping */
      margin-bottom: 68px;
    `}

  @media (min-width: ${({ theme }) => theme.mediaSizes.sm}) {
    margin-bottom: unset;
  }

  @media (min-width: ${({ theme }) => theme.mediaSizes.laptopL}) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: none;
  margin-bottom: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGrey};

  p {
    margin: 0;
  }

  p:not(:last-child) {
    margin-bottom: 6px;
  }

  @media (min-width: ${({ theme }) => theme.mediaSizes.laptopL}) {
    flex: 1;
    margin-bottom: 0;
    text-align: left;
  }
`;

const ChildrenWrapper = styled.div`
  margin-left: 32px;
  flex: none;
`;
