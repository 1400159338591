import { has } from 'ramda';

import { formatShortDate } from 'core/utils/date-fns';
import {
  centsToDollars,
  formatMoney,
  formatMoneyWithCurrency,
  formatWeight,
  isFumigationAccessorial,
  isStrapsAccessorial,
} from 'core/utils/lib';

export const whenRouteIs = (route, cases) => {
  if (route.status.is.active) {
    const hasCase = has('active');
    return hasCase(cases) ? cases.active : cases.fallback;
  }

  if (route.status.is.ready) {
    const hasCase = has('ready');
    return hasCase(cases) ? cases.ready : cases.fallback;
  }

  if (route.status.is.missingDetails) {
    const hasCase = has('missingDetails');
    return hasCase(cases) ? cases.missingDetails : cases.fallback;
  }

  if (route.status.is.expired) {
    const hasCase = has('expired');
    return hasCase(cases) ? cases.expired : cases.fallback;
  }

  if (route.status.is.unserviceable) {
    const hasCase = has('unserviceable');
    return hasCase(cases) ? cases.unserviceable : cases.fallback;
  }

  if (route.status.is.requested) {
    const hasCase = has('requested');
    return hasCase(cases) ? cases.requested : cases.fallback;
  }

  return cases.fallback;
};

const COUNTRY_ABBR_MAP = {
  mx: 'Méx',
  us: 'USA',
};

export const hasPartialDetails = value =>
  value.addressLine1 && value.addressLine2;

export const formatAddress = ({ addressLine1, zipCode }) => {
  const {
    name: zipCodeName,
    parents: { city, state, country },
  } = zipCode;

  return `${addressLine1}, ${city}, ${zipCodeName} ${state}, ${COUNTRY_ABBR_MAP[country]}`;
};

const CURRENCY_FORMAT_OPTIONS = { minimumFractionDigits: 2 };

export const formatPrices = data => {
  const { company, convertedPriceCents, convertedFuelCostCents, currency } =
    data;

  const baseCost = convertedPriceCents - convertedFuelCostCents;

  return {
    routePrice: formatMoney(
      centsToDollars(convertedPriceCents),
      CURRENCY_FORMAT_OPTIONS
    ),
    baseCost: company.variableFuel
      ? formatMoneyWithCurrency(
          centsToDollars(baseCost),
          currency,
          CURRENCY_FORMAT_OPTIONS
        )
      : undefined,
    fuelCost: formatMoneyWithCurrency(
      centsToDollars(convertedFuelCostCents),
      currency,
      CURRENCY_FORMAT_OPTIONS
    ),
  };
};

export const formatDetails = (data, t) => {
  const {
    id,
    handleBorderCrossing,
    serviceType,
    truckType,
    commodities,
    shipmentRequirements,
    lastUpdatedAt,
    company: { legalName: companyName },
  } = data;

  const routeDetails = {
    routeId: `RT-${id}`,
    companyName,
    ...(lastUpdatedAt && {
      lastUpdatedAt: formatShortDate(lastUpdatedAt),
    }),
    ...(truckType && { equipment: t(`route-equipment-${truckType}`) }),
    ...(serviceType && { serviceType: t(`route-serviceType-${serviceType}`) }),
    includeBorderCrossing: handleBorderCrossing
      ? t('route-includeBorderCrossing-yes')
      : t('route-includeBorderCrossing-no'),
  };

  if (commodities?.nodes?.length > 0) {
    const { name, category, weight, valuePerLoadCents } = commodities.nodes[0];
    routeDetails.commodity = {
      name,
      category: t(`route-commodity-${category}`),
      weight: weight ? formatWeight(weight) : t('route-commodity-weight-n/a'),
      value: formatMoney(
        centsToDollars(valuePerLoadCents),
        CURRENCY_FORMAT_OPTIONS
      ),
    };
  }

  const requirements =
    shipmentRequirements
      ?.filter(
        accessorial =>
          accessorial !== 'straps_needed' &&
          !isFumigationAccessorial({ id: accessorial })
      )
      .map(req => t(`route-requirement-${req}`)) || [];

  const getCommoditiesTags =
    commodities?.nodes[0]?.accessorials.map(accesorial => {
      if (isStrapsAccessorial(accesorial, 'slug')) {
        return `${commodities.nodes[0]?.extraStrapsCount} ${t(
          'general-extra-strap-text-label'
        )}`;
      }
      if (isFumigationAccessorial(accesorial, 'slug')) {
        return t('route-requirement-fumigation');
      }
      return accesorial.name;
    }) || [];

  routeDetails.specialHandling = [
    ...new Set([...requirements, ...getCommoditiesTags]),
  ];

  return routeDetails;
};

const createPoint = point => ({
  longitude: +point.zipCode.longitude,
  latitude: +point.zipCode.latitude,
});

export const buildMapLegs = (origin, destination) => {
  return [
    {
      origin: createPoint(origin),
      destination: createPoint(destination),
    },
  ];
};
