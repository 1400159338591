import { SHIPPER_API_VERSION } from 'core/utils/constants';
import request from 'core/utils/request';
import { assertGraphQLError, graphQLRequest } from 'core/utils/request';
import { CREATE_INVITE_MUTATION } from 'core/graphql';

const { REACT_APP_API_VERSION: API_VERSION = '/v0' } = process.env;

export const signIn = data =>
  request(`${API_VERSION}/login`, 'post', { body: data });

export const checkAuth = token =>
  request(`${API_VERSION}/check-auth`, 'get', {
    headers: token && {
      'X-NUVOCARGO-MAGIC-LINK-TOKEN': token,
    },
  }).then(({ data }) => data);

export const sendMagicLink = data =>
  request(`${API_VERSION}/magic-link`, 'post', { body: data });

export const signOut = () => request(`${API_VERSION}/logout`, 'get');

export const forgotPassword = email =>
  request(`${API_VERSION}/password/forgot`, 'post', { body: { email } });

export const resetPassword = ({ token, password }) =>
  request(`${API_VERSION}/password/reset`, 'post', {
    body: { token, password },
  });

export const verifyToken = ({ token }) =>
  request(`${API_VERSION}/password/token`, 'post', { body: { token } });

export const validateUser = async data =>
  await request(`${SHIPPER_API_VERSION}/onboarding/validate`, 'post', {
    body: data,
  });

export const createUserOnBoarding = async data =>
  await request(`${SHIPPER_API_VERSION}/onboarding`, 'post', { body: data });

export const checkInviteToken = inviteToken =>
  request(`${SHIPPER_API_VERSION}/invites/check`, 'get', {
    params: { invite_token: inviteToken },
  }).then(({ data }) => data);

export const updateLanguage = lng =>
  request(`${API_VERSION}/users/language`, 'put', {
    body: { language: lng },
  }).then(({ data }) => data);

export const completeProfile = data =>
  request(`${SHIPPER_API_VERSION}/onboarding/contact`, 'post', { body: data });

export const updateUser = ({ id, ...data }) =>
  request(`${API_VERSION}/users/${id}`, 'patch', { body: data });

export const createInvite = ({ emails, language = 'EN', signal }) =>
  graphQLRequest({
    body: {
      query: CREATE_INVITE_MUTATION,
      variables: {
        emails,
        language,
      },
    },
    signal,
  })
    .then(assertGraphQLError)
    .then(response => response?.data?.data);
