import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import * as FullStory from '@fullstory/browser';
import { useMutation } from 'react-query';

import * as usersApi from 'core/api/user';

export const useLanguageUpdate = () => {
  const { i18n } = useTranslation();
  const [languageValue, setLanguageValue] = useState(i18n.language);

  const { mutate, isLoading, error } = useMutation(usersApi.updateLanguage, {
    onSuccess: data => {
      i18n.changeLanguage(data?.language);
    },
  });

  return {
    changeLanguage: lng => () => {
      mutate(lng);
      setLanguageValue(lng);
      FullStory.event('language changed', {
        language: lng,
      });
    },
    onSelectChange: v => {
      setLanguageValue(v.value);
    },
    languageValue,
    isLoadingLanguageUpdate: isLoading,
    error,
  };
};
