import React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from 'components/generic/form';

export default function WrongPlatformMessage({ error }) {
  const { t } = useTranslation();

  if (!error) return;

  return (
    <ErrorMessage>
      {t(`shipper-login-error-wrong-platform-${error.message}-message`)}
      <a className="underline" href="https://carrier.nuvocargo.com/auth/login">
        {t(`shipper-login-error-wrong-platform-${error.message}-solution`)}
      </a>
    </ErrorMessage>
  );
}
