import React from 'react';

import { FeatureFlagsProvider } from './featureFlags';
import { UserProvider } from './user';

export default function Context({ children }) {
  return (
    <UserProvider>
      <FeatureFlagsProvider>{children}</FeatureFlagsProvider>
    </UserProvider>
  );
}
