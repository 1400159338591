import React from 'react';
import { Text } from '@nuvocargo/nuvo-styleguide';
import { TextField } from '@nuvocargo/nuvo-styleguide/forms/formik';
import { useTranslation } from 'react-i18next';

const Details = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      <div className="flex w-full flex-row gap-8 border-b border-solid border-nuvo-gray-dark/10 pb-1 ">
        <Text color="green" size="large">
          {t('facility-form-details-title')}
        </Text>
      </div>

      <TextField
        label={t('facility-form-name-label')}
        name="name"
        placeholder={t('facility-form-name-placeholder')}
      />
      <TextField
        label={t('facility-form-street-address-label')}
        name="addressLine1"
        placeholder={t('facility-form-street-address-placeholder')}
      />
      <TextField
        label={t('facility-form-street-address-2-label')}
        name="addressLine2"
        placeholder={t('facility-form-street-address-2-placeholder')}
      />
    </div>
  );
};

export default Details;
