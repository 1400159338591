import { gql } from 'graphql-request';

export const SHIPMENT_QUERY = gql`
  query customerui_getShipment($companyId: String, $shipmentId: ID) {
    shipments(companyId: $companyId, id: $shipmentId) {
      totalCount
      nodes {
        currentShipmentEvent {
          ...shipmentEventFields
        }
        customerReference
        origin {
          ...placeFields
        }
        destination {
          ...placeFields
        }
        externalId
        folioNumber
        id
        lastOccurredShipmentEvent {
          ...shipmentEventFields
        }
        legs {
          id
          origin {
            ...coordinates
          }
          destination {
            ...coordinates
          }
          trailerNumber
          lastLeg
          podSent
          pod {
            ...attachmentFields
          }
          receivableLineItems {
            edges {
              node {
                id
                invoiceXml {
                  ...attachmentFields
                }
                invoice {
                  ...attachmentFields
                }
              }
            }
          }
          pita {
            ...attachmentFields
          }
          doda {
            ...attachmentFields
          }
          manifest {
            ...attachmentFields
          }
          cartaPorte {
            xmlDocument {
              ...attachmentFields
            }
            fromClient {
              ...attachmentFields
            }
            fromCarrier {
              ...attachmentFields
            }
          }
        }
        state
        shipmentEvents(first: 50) {
          totalCount
          nodes {
            ...shipmentEventFields
          }
        }
        readyForPickup
        route {
          commodities {
            nodes {
              name
            }
          }
        }

        attachments {
          id
          category
          name
          createdAt
          document {
            ...attachmentFields
          }
        }
        documents {
          ...attachmentFields
        }
        customerInvoice {
          ...attachmentFields
        }
      }
    }
  }

  fragment placeFields on Place {
    ...coordinates
    name
    zipCode {
      parents {
        city
        country
      }
    }
  }

  fragment coordinates on Place {
    id
    latitude
    longitude
  }

  fragment shipmentEventFields on ShipmentEvent {
    actualDatetime
    estimatedDatetime
    id
    type
    airtableId
    notified
    notifyCustomer
  }

  fragment attachmentFields on Attachment {
    fileName
    url
    createdAt
  }
`;
