import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Formik, Form } from 'formik';
import styled, { css } from 'styled-components';
import {
  Grid,
  GridColumn,
  CTAButton,
  Link,
  Text,
  Heading,
} from '@nuvocargo/nuvo-styleguide';
import { TextField, Password } from '@nuvocargo/nuvo-styleguide/forms/formik';

import Layout from 'components/common/layout/unauthenticated';
import { ErrorMessage } from 'components/generic/form';
import { Stack } from 'components/generic/kit/';
import { auth, onboarding } from 'routes/paths';

import { initialValues, validationSchema } from './data';
import { useSignIn } from './hooks';
import WrongPlatformMessage from './components/WrongPlatformMessage';

export default function Login() {
  const { t } = useTranslation();
  const { errorData, isSigningIn, signIn } = useSignIn();
  return (
    <Layout>
      <Grid
        styles={{
          root: {
            alignItems: 'center',
            gridTemplateRows: '100vh',
          },
        }}>
        <GridColumn startMd={5} md={4} sm={4}>
          <Formik
            onSubmit={signIn}
            validationSchema={validationSchema(t)}
            initialValues={initialValues}>
            <Form>
              <Stack space={48}>
                <Header>
                  <Heading variant="h3" color="green">
                    {t('sign-in-title')}
                  </Heading>
                  <Text color="steel">{t('sign-in-subtitle')}</Text>
                </Header>

                <Stack space={24}>
                  <TextField
                    label={t('email')}
                    type="email"
                    name="email"
                    placeholder={t('placeholder-email')}
                  />
                  <Password
                    label={t('password')}
                    name="password"
                    placeholder={t('placeholder-password')}
                    data-testid="login-password"
                  />
                  <Link as={RouterLink} to={auth.forgotPassword}>
                    {t('link-forgot-password')}
                  </Link>
                  {errorData && (
                    <Row>
                      {errorData.key === 'wrong_platform' ? (
                        <WrongPlatformMessage error={errorData} />
                      ) : (
                        <ErrorMessage backgroundColor="taupe">
                          {t(`shipper-login-error-${errorData.message}`)}
                        </ErrorMessage>
                      )}
                    </Row>
                  )}
                </Stack>

                <Stack space={24} center>
                  <CTAButton disabled={isSigningIn} type="submit">
                    {t('btn-login')}
                  </CTAButton>
                  <Link as={RouterLink} to={onboarding.root}>
                    {t('link-dont-have-account')}
                  </Link>
                </Stack>
              </Stack>
            </Form>
          </Formik>
        </GridColumn>
      </Grid>
    </Layout>
  );
}

const Row = styled.div`
  margin: ${({ verticalSpacing }) =>
      verticalSpacing ? verticalSpacing : '40px'}
    0;
  ${({ alignCenter }) =>
    alignCenter &&
    css`
      display: flex;
      justify-content: center;
    `}
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  & > * + * {
    margin-top: 16px;
  }
`;
