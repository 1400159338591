import request from 'core/utils/request';
export const TOKEN =
  'pk.eyJ1IjoibXBlcnNvbmljazkwIiwiYSI6ImNrOWxodHE0MDBkNmUzaW1sZmJvM2c5emIifQ.s4cdD9WJlmjyVPeeBt0sYg';
export const getDirections = async coordinates =>
  await request(
    `/directions/v5/mapbox/driving/${coordinates}?geometries=geojson&access_token=${TOKEN}`,
    'get',
    {
      baseUrl: 'https://api.mapbox.com',
      withCredentials: false,
    }
  );

export const getOptimizedTrips = async coordinates =>
  await request(
    `/optimized-trips/v1/mapbox/driving/${coordinates}?roundtrip=false&destination=last&source=first&geometries=geojson&access_token=${TOKEN}`,
    'get',
    {
      baseUrl: 'https://api.mapbox.com',
      withCredentials: false,
    }
  );
