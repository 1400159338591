import { Heading, Text } from '@nuvocargo/nuvo-styleguide';
import { useTranslation } from 'react-i18next';
import Modal from '@nuvocargo/nuvo-styleguide/Modals/Modal';
import { useEffect, useReducer, useState } from 'react';

import { Facility } from './components/Facility';
import PlaceForm from './components/WizardSteps/PlaceForm';
import ConfirmationWizard from './components/WizardSteps/ConfirmationWizard';
import SelectExistingPlaceForm from './components/WizardSteps/SelectExistingPlaceForm';

export const STEPS = {
  SELECT_EXISTING_PLACE_FORM: 'SELECT_EXISTING_PLACE_FORM',
  PLACE_FORM: 'PLACE_FORM',
  CONFIRMATION: 'CONFIRMATION',
};

export const ACTIONS = {
  SET_STEP: 'SET_STEP',
  SET_PLACES: 'SET_PLACES',
  SET_MODAL_DATA: 'SET_MODAL_DATA',
  SET_CONFIRMATION_STEP: 'SET_CONFIRMATION_STEP',
};

const initialState = {
  origin: undefined,
  destination: undefined,
  step: STEPS.PLACE_FORM,
  facilityType: undefined, // origin, destination
  actionType: undefined, // ADD, EDIT
  modalData: undefined,
};

const getStepByPlace = (place, hasConfiguredPlace) => {
  if (hasConfiguredPlace) {
    return STEPS.SELECT_EXISTING_PLACE_FORM;
  }

  if (place.configured) {
    return STEPS.PLACE_FORM;
  }

  if (!place.name) {
    return STEPS.PLACE_FORM;
  }

  // It's not configured but has at least name
  return STEPS.PLACE_FORM;
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case ACTIONS.SET_PLACES:
      return {
        ...state,
        origin: action.payload.origin,
        destination: action.payload.destination,
      };
    case ACTIONS.SET_MODAL_DATA:
      return {
        ...state,
        facilityType: action.payload.facilityType,
        modalData: action.payload.data,
        step: getStepByPlace(
          action.payload.data,
          action.payload.hasConfiguredPlace
        ),
      };
    case ACTIONS.SET_CONFIRMATION_STEP:
      return {
        ...state,
        step: STEPS.CONFIRMATION,
        modalData: action.payload.modalData,
        from: action.payload.from,
      };
    default:
      throw new Error();
  }
}

export const Facilities = ({
  origin,
  destination,
  originPlaces,
  destinationPlaces,
  addErrorToast,
  addSuccessToast,
}) => {
  const { t } = useTranslation();
  // @todo: when _places_ from _routes_ return the _configured_ prop correctly we don't need to
  // @todo: made the _originPlaces.find_ or the _destinationPlaces.find_ functions anymore.
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    origin: {
      ...origin,
      // find origin math id -> configured
      configured: originPlaces.find(({ id }) => id === origin.id)?.configured,
    },
    destination: {
      ...destination,
      // find destination math id -> configured
      configured: destinationPlaces.find(({ id }) => id === destination.id)
        ?.configured,
    },
  });

  useEffect(() => {
    dispatch({
      type: ACTIONS.SET_PLACES,
      payload: {
        origin: {
          ...origin,
          // find origin math id -> configured
          configured: originPlaces.find(({ id }) => id === origin.id)
            ?.configured,
        },
        destination: {
          ...destination,
          // find destination math id -> configured
          configured: destinationPlaces.find(({ id }) => id === destination.id)
            ?.configured,
        },
      },
    });
  }, [dispatch, origin, originPlaces, destination, destinationPlaces]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const onCloseModal = () => {
    setModalIsOpen(false);
    dispatch({
      type: ACTIONS.SET_STEP,
      payload: STEPS.PLACE_FORM,
    });
  };

  const originConfiguredPlaces = originPlaces
    .filter(({ id }) => id !== origin.id)
    .filter(({ configured }) => configured);
  const destinationConfiguredPlaces = destinationPlaces
    .filter(({ id }) => id !== destination.id)
    .filter(({ configured }) => configured);
  return (
    <>
      <div className="px-6 pt-10">
        <Heading color="green">{t('facilities')}</Heading>
        <Text>{t('facilities-description')}</Text>
      </div>
      <Facility
        place={state.origin}
        testId="pickup"
        label={t('facility-pickup')}
        buttonOnClick={() => {
          setModalIsOpen(true);
          dispatch({
            type: ACTIONS.SET_MODAL_DATA,
            payload: {
              facilityType: 'origin',
              data: origin,
              hasConfiguredPlace: !!originConfiguredPlaces.length,
            },
          });
        }}
      />
      <hr className=" border-nuvo-gray-dark/10  " />
      <Facility
        place={state.destination}
        testId="dropoff"
        label={t('facility-dropoff')}
        buttonOnClick={() => {
          setModalIsOpen(true);
          dispatch({
            type: ACTIONS.SET_MODAL_DATA,
            payload: {
              facilityType: 'destination',
              data: destination,
              hasConfiguredPlace: !!destinationConfiguredPlaces.length,
            },
          });
        }}
      />

      <Modal onOpenChange={onCloseModal} isOpen={modalIsOpen}>
        {state.step === STEPS.SELECT_EXISTING_PLACE_FORM && (
          <SelectExistingPlaceForm
            dispatch={dispatch}
            placeData={state.modalData}
            places={
              state.facilityType === 'origin'
                ? originConfiguredPlaces
                : destinationConfiguredPlaces
            }
          />
        )}
        {state.step === STEPS.PLACE_FORM && (
          <PlaceForm
            placeData={state.modalData}
            dispatch={dispatch}
            facilityType={state.facilityType}
          />
        )}
        {state.step === STEPS.CONFIRMATION && (
          <ConfirmationWizard
            dispatch={dispatch}
            facilityType={state.facilityType}
            wizardState={state}
            onCloseModal={onCloseModal}
            addErrorToast={addErrorToast}
            addSuccessToast={addSuccessToast}
          />
        )}
      </Modal>
    </>
  );
};
